<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="row d-print-none">
            <div class="col-sm-12 mb-3 text-right">
                <button
                    class="btn btn-primary"
                    @click="printAll"
                >
                    <i class="mdi mdi-printer mr-1" />
                    Print All
                </button>
            </div>
        </div>

        <div class="row">
            <div
                v-for="index in orderProductColor.quantity"
                :key="`${index}-${key}`"
                class="col-xl-6 printable_instruction"
                :class="{
                    'd-print-none': (instructionToPrint !== `${orderProductColor.id}_${index}`) && !printAllInstructions
                }"
            >
                <single-instruction
                    :order-product-color="orderProductColor"
                    :index="index"
                    :sizes="sizes"
                    @print="onPrint"
                    @edit="onEdit"
                />
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@/config';
import appConfig from '@src/app.config';
import SingleInstruction from '@components/factory-data/SingleInstruction';

export default {
    page: {
        title: 'Factory Data Instructions',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        SingleInstruction
    },

    data() {
        return {
            title: 'Factory Data Instructions',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Factory Data',
                    to: '/factory-data'
                },
                {
                    text: 'Instructions',
                    active: true
                }
            ],
            orderProductColor: {},
            sizes: [],
            instructionToPrint: null,
            printAllInstructions: false,
            key: 0
        };
    },

    async mounted() {
        this.orderProductColorId = this.$route.params.id;

        await this.fetchOrderProductColor();
        await this.fetchSizes();
    },

    beforeDestroy() {
        window.removeEventListener('focus', this.handleFocusChange, false);
    },

    methods: {
        ...mapActions({
            getOrderProductColor: 'orderProductColors/show',
            getSizes: 'productSizes/index',
            createOrUpdateInstruction: 'orderProductColors/createOrUpdateInstruction'
        }),

        async fetchOrderProductColor() {
            try {
                this.orderProductColor = await this.getOrderProductColor(
                    this.orderProductColorId
                );

                this.key++;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async fetchSizes() {
            try {
                this.sizes = await this.getSizes();
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onEdit(orderProductColorId) {
            window.addEventListener('focus', this.handleFocusChange, false);

            window.open(
                `${config.url}/factory-data/${orderProductColorId}/instructions/edit`,
                '_blank',
                'location=yes, height=800, width=520, scrollbar=yes, status=yes'
            );
        },

        handleFocusChange() {
            this.fetchOrderProductColor();

            window.removeEventListener('focus', this.handleFocusChange, false);
        },

        async onPrint(id) {
            this.instructionToPrint = id;

            await this.$nextTick();

            window.print();
        },

        async printAll() {
            this.printAllInstructions = true;

            await this.$nextTick();

            window.print();

            this.printAllInstructions = false;
        }
    }
};
</script>

<style scoped lang="scss">
.printable_instruction {
    font-size: 16px;
    color: #343a40;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    page-break-after: always;

    p {
        margin: 0;
        padding: 0;
    }
}
</style>
